import React, {useEffect, useState} from 'react';
import {Icon, makeStyles} from '@material-ui/core';
import '../../css/GlobalStyle.css';
import AttachFileIcon from '@mui/icons-material/AttachFileRounded';

function MediaItem({media, display}) {
  const classes = useStyles();
  const [contentType] = useState(createContentType(media));
  const [size] = useState(createSize(display, contentType));
  const [content, setContent] = useState(createContent(media, contentType, size, classes));
  useEffect(() => setContent(createContent(media, contentType, size, classes)), [media, size, classes]);

  return (
    <div
      className={classes.container}
      style={{width: size.width, height: size.height}}
      onClick={()=> window.open(media.url, media.file.name)}
    >
      {content}
    </div>
  );

  function createContent(media, contentType, size, classes) {
    switch (contentType) {
      case 'image':
        return createImageContent(media, size, classes);
      case 'video':
        return createVideoContent(media, size, classes);
      default:
        return createGenericContent(media, size, classes);
    }
  }

  function createImageContent(media, size) {
    return (
      <img width={size.width} height={size.height}
        key={media.file.url}
        src={media.url}
      />
    );
  }

  function createVideoContent(media, size) {
    return (
      <video autoPlay loop muted width={size.width} height={size.height}>
        <source src={media.url} type="video/mp4"/>
        <img src={media.url} />
      </video>
    );
  }

  function createGenericContent(media, size, classes) {
    return (
      <div className={classes.genericContainer}>
        <Icon className={classes.AttachFileIcon} size="small" onClick={() => setOpenFileDialog(true)}>
          <AttachFileIcon fontSize="small"/>
        </Icon>

        <p className={classes.genericTitle} style={{width: `${size.width - 24}px`, height: size.height}}>
          {media.file.name}
        </p>
      </div>
    );
  }

  function createContentType(media) {
    switch (media.file.extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        return 'image';
      case 'mp4':
      case 'mov':
        return 'video';
      default:
        return 'generic';
    }
  }

  function createSize(display, contentType) {
    let width = 0;
    let height = 0;

    switch (display) {
      case 'issue':
        width = 100;
        height = 75;
        break;
      case 'comment':
        if (contentType == 'generic') {
          width = 'auto';
          height = 'auto';
        } else {
          width = 50;
          height = 40;
        }
        break;
    }

    return {
      width,
      height,
    };
  }
};

const useStyles = makeStyles(() => ({
  container: {
    // background: 'white',
  },
  genericContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  genericTitle: {
    textAlign: 'center',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    fontStyle: 'italic',
    fontSize: '0.8rem',
    color: '#848484',
    fontWeight: '400 !important',
    wordWrap: 'break-word',
    overflow: 'hidden',
  },
  AttachFileIcon: {
    color: '#848484',
  },
}));

export default MediaItem;

